/* eslint-disable import/no-named-as-default */
import React from 'react';
import Helmet from 'react-helmet';
import useSiteMetadata from './useSiteMetadata';
import defaultImage from '../../images/favicon.png';

const SEO = ({ description, meta, title, image }) => {
  const siteMetadata = useSiteMetadata();

  const metaDescription = description || siteMetadata.description;
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Helmet
      htmlAttributes={{
        hreflang: 'en-US',
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: 'google-site-verification',
          content: process.env.GOOGLE_SEARCH_CONSOLE_ID,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image || defaultImage,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:locale`,
          content: 'en_US',
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
