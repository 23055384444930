const styles = ({ palette }) => ({
  Wrapper: {
    width: 280,
    marginBottom: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  TextWrapper: {
    paddingBottom: '0px !important',
  },
  ButtonLink: {
    color: '000',
    '& > span > a': {
      color: palette.button.primary,
    },
  },

  ButtonAmazon: {
    color: '000',
    '& > span > a': {
      color: palette.button.primary,
    },
    marginTop: '-5px',
  },

  ButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    '& > p': {
      margin: 0,
    },
    '& > div': {
      marginBottom: -10,
      marginLeft: 8,
    },
  },
  ButtonBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
  },
  DisableButtonBox: {
    width: '100%',
    display: 'flex',
    '& button': {
      width: '50%',
    },
  },
});

export default styles;
