import { useStaticQuery, graphql } from 'gatsby';

const homeCarouselData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHomeCarousel {
          nodes {
            carouselPicture {
              fluid(quality: 50) {
                ...GatsbyContentfulFluid
              }
            }
            article {
              articleLink
              articleTitle
            }
          }
        }
        contentfulGeneralContent {
          homeCarouselLabel {
            json
          }
        }
      }
    `,
  );
  return data;
};

export default homeCarouselData;
