import React from 'react';
import { withStyles } from '@material-ui/styles';
import AvatarIcon from '../../images/svg/logo.svg';

import styles from './style';

const Avatar = ({ classes }) => (
  <div className={classes.container}>
    <AvatarIcon />
  </div>
);

export default withStyles(styles)(Avatar);
