import { useEffect, useState } from 'react';

const useMobile = (mobileSize) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // eslint-disable-next-line no-unused-expressions
      window.innerWidth < mobileSize ? setIsMobile(true) : setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isMobile;
};

export default useMobile;
