const styles = ({ palette }) => ({
  Notifications: {
    height: '1.5rem',
    width: '100%',
    backgroundColor: palette.notifications.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    '& span': {
      color: 'white',
    },
  },
});

export default styles;
