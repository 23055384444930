const styles = ({ app, typography }) => ({
  container: {
    padding: '0 !important',
    margin: `${typography.pxToRem(0)} auto 0`,
    width: '100%',
    paddingLeft: typography.pxToRem(20),
    paddingRight: typography.pxToRem(20),
    position: 'sticky' /* Липкое позиционирование */,
    top: 0,
    background: 'rgb(255,255,255, 0.6)',
    // background: color.fade((255, 255, 255), 0.6),
    // result = `rgba(${r},${g},${b},${opacity || 1})`;
    zIndex: 100,
  },
  containerOpaque: {
    padding: '0 !important',
    margin: `${typography.pxToRem(0)} auto 0`,
    width: '100%',
    paddingLeft: typography.pxToRem(20),
    paddingRight: typography.pxToRem(20),
    position: 'sticky' /* Липкое позиционирование */,
    top: 0,
    zIndex: 100,
    background: 'white',
  },
  LinksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ` 0 ${typography.pxToRem(20)}`,
    maxWidth: app.maxWidth,
    margin: 'auto',
  },
  logo: {
    border: 'none',
    float: 'left',
    transition: 'all .3s',
    textDecoration: 'none',
  },
  navigation: {
    float: 'right',
    position: 'relative',
  },
});

export default styles;
