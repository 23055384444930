const styles = ({ palette }) => ({
  BackgroundSection: {
    width: '100%',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    '& section': {
      height: 600,
    },
  },
  DescriptionWrapper: {
    position: 'relative',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  Description: {
    position: 'absolute',
    bottom: 50,
    color: 'white',
    opacity: 1,
    fontSize: '3rem',
    '&:hover': {
      color: palette.hover.tawny,
    },
  },
});

export default styles;
