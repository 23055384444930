import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import Animated from '../animated/Animated';
import styles from './style';
import generalData from '../../queries/general.module';

const textArrayFunc = (allContentfulGeneralContentNotifaicationsJsonNode) => {
  return allContentfulGeneralContentNotifaicationsJsonNode.nodes.map(
    (i) => i.content,
  );
};

const Notifications = ({ classes }) => {
  const [textIdx, setTextIndex] = useState(0);
  const { allContentfulGeneralContentNotifaicationsJsonNode } = generalData();

  const textArray = textArrayFunc(
    allContentfulGeneralContentNotifaicationsJsonNode,
  );

  useEffect(() => {
    const timeout = setInterval(() => {
      setTextIndex(textIdx + 1);
    }, 3500);

    return () => {
      clearInterval(timeout);
    };
  }, [textIdx, setTextIndex]);

  const textThatChanges = textArray[textIdx % textArray.length];

  return (
    <Fade in timeout={1500}>
      <h3 className={classes.Notifications}>
        <Animated>{textThatChanges}</Animated>
      </h3>
    </Fade>
  );
};

export default withStyles(styles)(Notifications);
