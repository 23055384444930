const styles = ({ app }) => ({
  imageWrapper: {
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '480px',
  },
  image: {
    minHeight: '480px',
    height: '100%',
  },
  Title: {
    textAlign: 'center',
    lineHeight: '1.5',
    padding: '50px 0px 50px 0px',
    width: '90%',
    margin: 'auto',
    fontWeight: 'bold',
    maxWidth: app.maxWidth,
  },

  Сarousel: {
    '& .slick-dots ': {
      marginBottom: '-10px',
      '& button::before': {
        fontSize: '2rem',
      },
    },
    // position: 'relative',
  },
});

export default styles;
