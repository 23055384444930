import Link from 'gatsby-link';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'utils';
import { connectProps, toggleMenu } from 'state';
import Avatar from '../avatar';
import Navigation from '../navigation';
import Animated from '../animated';
import Notification from '../notifications/Notifications';

import styles from './style';

const Header = ({ classes }) => {
  const store = useSelector((state) => state);
  const { open } = store;
  return (
    <header className={!open ? classes.container : classes.containerOpaque}>
      <Notification />
      <div className={classes.LinksWrapper}>
        <Link to='/' className={classes.logo} onClick={() => toggleMenu(false)}>
          <Animated>
            <Avatar />
          </Animated>
        </Link>
        <div className={classes.navigation}>
          <Navigation open={open} toggleMenu={toggleMenu} />
        </div>
      </div>
    </header>
  );
};

export default compose(
  connectProps(toggleMenu),
  withWidth(),
  withStyles(styles),
)(Header);
