/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import HomeCarousel from '../homeCarousel/HomeCarousel';

import styles from './style';

const Layout = ({ classes, children, noBackground, page }) => {
  const isCarouselShown = page === 'Products';
  return (
    <>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        // autoHeightMin={0}
        // autoHeightMax={200}
        // thumbMinSize={30}
        universal
        renderView={(props) => <div {...props} className={classes.scrollFix} />}
        className={classes.container}
      >
        <Header />
        {isCarouselShown && <HomeCarousel />}

        <div
          className={classNames(
            classes.pageContainer,
            !noBackground && classes.background,
          )}
        >
          <Fade in mountOnEnter unmountOnExit>
            <main className={classes.content}>{children}</main>
          </Fade>
        </div>
        <Footer />
      </Scrollbars>
    </>
  );
};

export default withStyles(styles)(Layout);
