/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import homeCarouselData from '../../queries/homeCarousel.module';
import ContentfulContent from '../contentfulContent/ContentfulContent';
import BackgroundImage from '../backgroundImage/BackgroundImage';

import styles from './style';

const slidesElementsFluid = (classes, carouselData) =>
  carouselData.map((i) => {
    const { carouselPicture } = i;
    const article = i && i.article ? i.article : '';
    const articleLink =
      article && article.articleLink ? article.articleLink : '';
    const articleTitle =
      article && article.articleTitle ? article.articleTitle : '';

    const { fluid } = carouselPicture;

    return (
      <div className={classes.imageWrapper} key={articleLink}>
        <BackgroundImage
          images={fluid}
          title={articleTitle}
          articleLink={articleLink}
        />
      </div>
    );
  });

const HomeCarousel = ({ classes }) => {
  const { allContentfulHomeCarousel, contentfulGeneralContent } =
    homeCarouselData();

  const carouselData = allContentfulHomeCarousel
    ? allContentfulHomeCarousel.nodes
    : [];

  const title = contentfulGeneralContent
    ? contentfulGeneralContent.homeCarouselLabel
    : {};

  return (
    <div className={classes.HomeCarouselWrapper}>
      <div className={classes.Title}>
        <Fade in timeout={1500}>
          <ContentfulContent content={title?.json} />
        </Fade>
      </div>
      <Fade in timeout={1500}>
        <Slider
          className={classes.Сarousel}
          arrows={false}
          dots
          infinite
          slidesToShow={1}
          slidesToScroll={1}
          autoplay
          speed={2000}
          autoplaySpeed={5500}
          pauseOnHover={false}
          lazyLoad
          useTransform
        >
          {slidesElementsFluid(classes, carouselData)}
        </Slider>
      </Fade>
    </div>
  );
};

export default withStyles(styles)(HomeCarousel);
