import { useStaticQuery, graphql } from 'gatsby';

const generalData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulGeneralContentNotifaicationsJsonNode {
          nodes {
            content
          }
        }
      }
    `,
  );
  return data;
};

export default generalData;
