const styles = () => ({
  container: {
    overflow: 'hidden',
    cursor: 'pointer',
    '& > svg': {
      cursor: 'pointer',
    },
  },
});

export default styles;
