const styles = ({ app, palette }) => ({
  Container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 200,
    background: '#e3e2e2',
    backgroundColor: palette.footer.background,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    '& svg': {
      height: '2.5rem',
      width: '2.5rem',
    },
  },
  ContentWrapper: {
    width: app.maxWidth,
    padding: '20px 0',
  },
  TitleLinks: {
    textAlign: 'end',
  },
  LinkName: {
    color: 'black',
    fontSize: '13px',
    textAlign: 'end',
  },
  NetworksBox: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      paddingLeft: '1rem',
    },
    '&:not(last-child)': {
      paddingBottom: '0.8rem',
    },
  },
  Link: {
    textDecoration: 'none',
    color: 'black',
  },
});

export default styles;
