import React from 'react';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import { Sling as Hamburger } from 'hamburger-react';

import styles from './style';

const MobileNavigation = ({ classes, options, onClick, open }) => {
  return (
    <>
      <Hamburger toggled={open} onToggle={onClick} />
      {open && (
        <div className={classes.menuContainer}>
          {options.map((link, i) => (
            <div key={i}>
              <Link
                to={link.to}
                className={classes.navLink}
                activeClassName={classes.navLinkActive}
                exact='true'
                onClick={() => onClick(false)}
              >
                <span className={classes.Links}>{link.text}</span>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(MobileNavigation);
