import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import styles from './style';

const useStyles = makeStyles(styles);

const BackgroundSection = ({ images, title, articleLink }) => {
  const classes = useStyles();
  return (
    <div className={classes.BackgroundSection}>
      <BackgroundImage Tag='section' fluid={images} title={title} />
      <Link
        to={`article/${articleLink}`}
        target='_blank'
        style={{ textDecoration: 'none' }}
      >
        <div className={classes.DescriptionWrapper}>
          <p className={classes.Description}>{title}</p>
        </div>
      </Link>
    </div>
  );
};

export default BackgroundSection;
