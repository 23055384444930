const styles = () => ({
  productsWrapper: {
    margin: 'auto',
    padding: 0,
    paddingTop: '3rem',
  },
  Title: {
    textAlign: 'center',
    paddingTop: 30,
    fontWeight: 700,
  },
  cardsWrapper: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'wrap',
  },
});

export default styles;
