import React, { useMemo } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

import useMobile from '../../utils/useMobile';

const padding = (isMobile, floatProperty) => {
  if (floatProperty === 'left' && !isMobile) {
    return '1rem 1rem 1rem 0rem';
  }
  if (floatProperty === 'right' && !isMobile) {
    return '1rem 0rem 1rem 1rem';
  }
  return '0rem';
};

const ImageContentfulRender = ({
  imgSrc,
  floatProperty,
  size,
  paddingProperty,
}) => {
  return (
    <div style={{ maxWidth: `${size?.width}px`, float: floatProperty }}>
      <img
        style={{
          padding: paddingProperty,
          width: '100%',
        }}
        alt='pic'
        src={imgSrc}
      />
    </div>
  );
};

const ContentfulContent = (props) => {
  const { content: homeContent } = props;
  const isMobile = useMobile(500);

  const options = useMemo(
    () => ({
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: () => {
          const imgData = homeContent.content.find(
            (i) => i.nodeType === 'embedded-asset-block',
          ).data.target.fields;
          const imgSrc = imgData?.file['en-US']?.url;
          const size = imgData ? imgData?.file['en-US'].details?.image : {};
          const floatProperty = imgData ? imgData?.description['en-US'] : '';
          const paddingProperty = padding(isMobile, floatProperty);

          return (
            <ImageContentfulRender
              imgSrc={imgSrc}
              size={size}
              floatProperty={floatProperty}
              paddingProperty={paddingProperty}
            />
          );
        },
      },
    }),
    [isMobile, homeContent],
  );

  return useMemo(() => {
    const content =
      typeof homeContent === 'string' ? JSON.parse(homeContent) : homeContent;
    return <>{documentToReactComponents(content, options)}</>;
  }, [homeContent]);
};

export default ContentfulContent;
