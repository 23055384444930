import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  Button,
  Typography,
} from '@material-ui/core';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import styles from './style';

const useStyles = makeStyles(styles);

const AmazonButton = ({ classes, productLink, logo, price }) => {
  return (
    <>
      {productLink && price > 0 ? (
        <Button size='small' color='primary' className={classes.ButtonAmazon}>
          <a
            href={productLink}
            target='_blank'
            style={{ textDecoration: 'none' }}
            rel='noreferrer'
          >
            <div className={classes.ButtonWrapper}>
              <p className={classes.AmazonText}>By on </p>
              {logo ? (
                <Img className={classes.Logo} fixed={logo.fixed} alt='logo' />
              ) : null}
            </div>
          </a>
        </Button>
      ) : (
        <Button
          size='small'
          color='primary'
          disabled
          className={classes.ButtonAmazon}
        >
          <div className={classes.ButtonWrapper}>
            <p className={classes.AmazonText}>Currently not available</p>
          </div>
        </Button>
      )}
    </>
  );
};

const productCard = ({
  img,
  title,
  shortDescription,
  url,
  logo,
  productLink,
  price,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.Wrapper}>
      <CardActionArea>
        <Link to={`/product/${url}`} style={{ textDecoration: 'none' }}>
          <Img fluid={img.fluid} alt={title} />
          <CardContent className={classes.TextWrapper}>
            <Typography
              gutterBottom
              variant='h5'
              component='h2'
              color='primary'
            >
              {title}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {shortDescription}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>

      <CardActions>
        <div
          className={productLink ? classes.ButtonBox : classes.DisableButtonBox}
        >
          <Button size='small' color='primary' className={classes.ButtonLink}>
            <Link to={`/product/${url}`} style={{ textDecoration: 'none' }}>
              See details...
            </Link>
          </Button>
          <AmazonButton
            classes={classes}
            productLink={productLink}
            logo={logo}
            price={price}
          />
        </div>
      </CardActions>
    </Card>
  );
};

export default productCard;
