const styles = ({ palette, typography }) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  menuContainer: {
    position: 'fixed',
    top: typography.pxToRem(107),
    right: typography.pxToRem(0),
    textAlign: 'center',
    padding: `${typography.pxToRem(16)} ${typography.pxToRem(8)}`,
    background: '#fff',
    width: '100vw',
    height: '100vh',
    '& div': {
      padding: `${typography.pxToRem(16)} ${typography.pxToRem(0)}`,
    },
  },
  navLink: {
    border: 'none',
    display: 'inline-block',
    padding: `${typography.pxToRem(8)} 0`,
    textDecoration: 'none',
    color: palette.text.secondary,
    fontSize: typography.pxToRem(16),
  },
  navLinkActive: {
    fontWeight: 700,
  },
  Links: {
    fontSize: typography.pxToRem(30),
  },
});

export default styles;
