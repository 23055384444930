import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import Link from 'gatsby-link';
import { FaInstagram } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { useSiteMetadata } from '../seo/useSiteMetadata';

import styles from './style';

const links = [
  {
    name: 'All products',
    path: '/',
  },
  {
    name: 'Articles',
    path: '/articles/',
  },
  {
    name: 'About us',
    path: '/about/',
  },
];

const QuickLinks = ({ classes }) => {
  return (
    <>
      <h3 className={classes.TitleLinks}>QuickLinks</h3>
      {links.map((link) => {
        return (
          <Link key={link.name} to={link.path} className={classes.Link}>
            <p className={classes.LinkName}>{link.name}</p>
          </Link>
        );
      })}
    </>
  );
};

const SocialNetworks = ({ classes }) => {
  const siteMetadata = useSiteMetadata();
  const { instagramUserName, tikTokUserName } = siteMetadata;
  const socialNetworksLinks = [
    {
      href: 'https://www.instagram.com/dogify_store/',
      label: instagramUserName,
      icon: <FaInstagram />,
    },
    {
      href: 'https://www.tiktok.com/@dogifystore',
      label: tikTokUserName,
      icon: <SiTiktok />,
    },
  ];
  return (
    <div>
      <h3>Our social networks</h3>
      {socialNetworksLinks.map((item, index) => {
        const { href, label, icon } = item;
        return (
          <a
            key={href + index}
            href={href}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.Link}
          >
            <div className={classes.NetworksBox}>
              <div>{icon}</div>
              <span>{label}</span>
            </div>
          </a>
        );
      })}
    </div>
  );
};

const Footer = ({ classes }) => {
  return (
    <Container maxWidth='xl' className={classes.Container}>
      <Grid container spacing={3} className={classes.ContentWrapper}>
        <Grid item xs={4}>
          <div className={classes.NetworkWrapper}>
            <SocialNetworks classes={classes} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.NetworkskBox} />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.QuickLinks}>
            <QuickLinks classes={classes} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(Footer);
