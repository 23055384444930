/* eslint-disable no-shadow */
import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import withWidth from '@material-ui/core/withWidth';

import { compose, isMobileView } from 'utils';
import { connectProps, open, toggleMenu } from 'state';

import MainNavigation from './main';
import MobileNavigation from './mobile';
import options from './content';
import styles from './style';

const Navigation = ({ classes, width, toggleMenu }) => {
  const store = useSelector((state) => state);
  const { open } = store;
  return (
    <div className={classes.container}>
      {isMobileView(width) ? (
        <MobileNavigation
          options={options}
          open={open}
          onClick={() => toggleMenu()}
        />
      ) : (
        <MainNavigation options={options} />
      )}
    </div>
  );
};

export default compose(
  connectProps(open, toggleMenu),
  withWidth(),
  withStyles(styles),
)(Navigation);
